
<template>
  <div id="category-show-page" class="pb-4">
    <div v-if="!isLoading">
      <div v-if="item" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h3 class=""> {{item.nickname}}</h3>
              <h4 class="header-title mb-2">Display name: {{item.title}}</h4>
              <p class="mb-0">{{ item.description }}</p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 text-right">
              <b-button variant="primary" class=""
                @click.prevent="popupModalManage = true" type="button">
              <span class=""> Manage Requirements</span></b-button>
            </div>
          </div>
          <div role="tablist" class="">
            <b-card no-body class="mb-1 shadow-none" 
              v-for="(requirement, index) in pageData.data" :key="index">
              <b-card-header header-tag="header" class="bg-transparent" role="tab">
                <div class="d-sm-flex">
                  <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                    <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                      <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{requirement.name}}
                    </a>
                  </h5>
                </div>
              </b-card-header>
              <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
                <b-card-body>
                  <requirement-card :item="requirement">
                    <template v-slot:actions>
                      <div>
                        <b-button variant="success" size="sm" class="mr-2" @click.prevent="initEdit(requirement)" type="button">
                          Edit </b-button>
                        <b-button variant="primary" v-if="requirement.multiple" size="sm" class="mr-2"
                        @click.prevent="showValues(requirement)" type="button">
                          View Values </b-button>
                        <b-button variant="danger" size="sm" @click.prevent="deleteItem(requirement.id)" type="button">
                          Delete</b-button>
                      </div>
                    </template>
                  </requirement-card>
                  <div v-if="isShowItemValues && showItem.id == requirement.id">
                    <hr />
                    <requirement-values :showItem="requirement" />
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode ? 'Update requirement':'Add requirement'"
      title-class="" @hide="initializeAll()">
      <requirement-form v-if="popupModalShow" @closeMe="initializeAll()"
       :editMode="editMode" :editItem="showItem" />
    </b-modal>

    <b-modal v-model="popupModalManage" hide-footer modal-class="modal-fullscreen"
      title="Select requirements for this section" body-class="loading-viewport"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <section-item-form if="popupModalManage" @closeMe="initializeAll()"
          @updated="updateItem($event)"
          :editItem="item" />
      </div>
    </b-modal>
  </div>
</template>
<script>

import IsLoading from "@/components/IsLoading.vue"
import RequirementValues from './RequirementValues.vue'
import RequirementCard from '@/components/cards/RequirementCard.vue'
import RequirementForm from '@/components/forms/RequirementForm.vue'
import SectionItemForm from '@/components/forms/SectionItemForm.vue';

export default{
  components: {
    IsLoading,
    RequirementValues,
    RequirementCard,
    RequirementForm,
    SectionItemForm
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      popupModalManage: false,
      isShowItemValues: false,
      item: null,
      editMode: false,
      showItem: null,
    }
  },
  computed: {
    pageData(){
      return this.$store.state.requirement.pageData
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.item, item)
      this.$store.commit("requirement/SET_PAGE_DATA", JSON.parse(JSON.stringify(this.item.requirements)))
    },
    fetchItem(){
      this.$http.get(`/requirement-sections/${this.$route.params.sectionId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
          this.updateItem(this.item)
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this requirement!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/requirements/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.$store.commit("requirement/DELETE_ITEM", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.showItem = {...item}
      this.popupModalShow = true  
    },
    showValues(item){
      if(this.isShowItemValues) {
        this.initializeAll(); return
      }
      this.showItem = {...item}
      this.isShowItemValues = true  
    },
    initializeAll(){
      this.editMode = false;
      this.showItem = null;
      this.popupModalShow = false;
      this.isShowItemValues = false;
      this.popupModalManage = false;
    },
  },
  mounted(){
    this.fetchItem()
  },
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-size:100%;
        background-repeat-y: no-repeat;
    }
    .faq-bg {
        background-color: #fff;
    }
}
</style>
